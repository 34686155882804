import { Button, DownloadIcon, EmailIcon, LinkIcon, PrintIcon, RssIcon, Table, TableBody, TableCaption, TableCell, TableHead, TableRow, WhatsNewIcon } from '@cmsgov/design-system';
import * as React from 'react';
export default {
  Button,
  DownloadIcon,
  EmailIcon,
  LinkIcon,
  PrintIcon,
  RssIcon,
  Table,
  TableBody,
  TableCaption,
  TableCell,
  TableHead,
  TableRow,
  WhatsNewIcon,
  React
};